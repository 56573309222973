<template>
  <div class="body">
    <div id="findpasswordemailone">
      <h1>二步驗證</h1>
      <h4>已將驗證碼寄送至您的信箱，請至信箱確認，並填寫正確驗證碼，要是過久未收到或超過驗證時間，請點選再次獲取驗證碼。碰上問題?線上客服</h4>
      <div class="emailBox">
        <h2>{{$store.state.email}}</h2>
        <div class="codeSendBox">
          <btn-submit class="btnLogin" :height="40" color="red" :disable='disable' @click="sendCode()">{{getCode}}</btn-submit>
        </div>
      </div>
      <div class="verification">
        <verification-code-box @allCode="allCode"></verification-code-box>
      </div>
      <div class="btnBox">
        <btn-submit :width="460" @click="twoStepLogin()">Login</btn-submit>
      </div>
    </div>
    
  </div>
</template>

<script>
import VerificationCodeBox from '@/components/form/VerificationCodeBox.vue'
import BtnSubmit from '@/components/button/BtnSubmit'
import {twoStepLogin} from "../../../axios/api/login"
import cookie from 'js-cookie'
import { verifyEmail } from '@/axios/api/login'
export default {
  name:'TwoStepLogin',
  components: {
    VerificationCodeBox,
    BtnSubmit,
  },
  data() {
    return {
      loginForm:{},
      disable:false,
      count:60,
      getCode:"Send"
    }
  },
  methods:{
    twoStepLogin(){
      this.loginForm.email = this.$store.state.email
      this.loginForm.password = this.$store.state.password
      twoStepLogin(this.loginForm).then(res=>{
        if(res.code===20000){
          //将 token 写入 cookie
          cookie.set('userInfo',res.data.userInfo)
          window.location.href = '/usercenter'
        }
        else {
          console.log("登录失败")
        }
      })
    },
    allCode(item){
      this.loginForm.code = item
    },
    sendCode(){
      this.disable = true;
      verifyEmail(this.$store.state.email).then(res=>{
        if(res.code == 20000){
          console.log(this.$store.state.email)
        }
      })
      var countDown = setInterval(()=>{
        if(this.count < 1){
          this.disable = false;
          this.getCode = 'Send';
          this.count = 60;
          clearInterval(countDown);
        }else{
          this.disable = true;
          this.getCode = this.count-- + 's Resend';
        }
      },1000);
    },
  },
}
</script>

<style scoped>
.body{
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  width: 100%;
}
#findpasswordemailone{
  max-width: 600px;
  width: 600px;
  height: 560px;
}
h4{
  margin-bottom: 40px;
  color: rgb(146, 146, 146);
  margin-top: 20px;
}
.emailBox{
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}
.codeSendBox{
  position: absolute;
  height: 100%;
  top: 0;
  left: 100%;
  width: 50px;
  display: flex;
}
.btnBox{
  margin-top: 20px;
}
</style>
